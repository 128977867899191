/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Resource cloud provider and type.
*/
export enum ResourceType {
  AwsEc2 = 'AWS_EC2',
  AwsRds = 'AWS_RDS',
  AwsS3 = 'AWS_S3',
  AwsEfs = 'AWS_EFS',
  AwsEksNamespace = 'AWS_EKS_NAMESPACE',
  AwsFileShare = 'AWS_FILE_SHARE',
  AwsDynamoDb = 'AWS_DYNAMO_DB',
  AzureVirtualMachine = 'AZURE_VIRTUAL_MACHINE',
  AzureCloudSql = 'AZURE_CLOUD_SQL',
  AzureStorageAccount = 'AZURE_STORAGE_ACCOUNT',
  AzureDisk = 'AZURE_DISK',
  AzureSqlDatabase = 'AZURE_SQL_DATABASE',
  AzureSqlManagedInstance = 'AZURE_SQL_MANAGED_INSTANCE',
  AzureMysql = 'AZURE_MYSQL',
  AzurePostgresql = 'AZURE_POSTGRESQL',
  AtlasMongodbCluster = 'ATLAS_MONGODB_CLUSTER',
  GcpComputeEngine = 'GCP_COMPUTE_ENGINE',
  ResourceTypeUnspecified = 'RESOURCE_TYPE_UNSPECIFIED'
}
