import type {
  RestoreVolumeToEbsSnapshotRequest,
  Configuration,
  ConvertResourceSnapshotsRequest,
  ConvertOtherSnapshotsOnDemandRequest,
} from '@repo/api-gw-sdk';
import {
  ConvertOtherSnapshotsOnDemandApi,
  ConvertResourceSnapshotsApi,
  ListCloudSnapshotsApi,
  ListDailySnapshotsApi,
  SnapshotsApi,
} from '@repo/api-gw-sdk';

import dayjs from '@/utils/dayjs';

import type { HttpClient } from './httpClient';

const baseUrl = '/convert';

export const convert = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new SnapshotsApi(config);
  const convertResourceSnapshotsAPI = new ConvertResourceSnapshotsApi(config);
  const convertOtherSnapshotsOnDemandApi = new ConvertOtherSnapshotsOnDemandApi(
    config
  );
  const listCloudSnapshotsAPI = new ListCloudSnapshotsApi(config);
  const listDailySnapshotsAPI = new ListDailySnapshotsApi(config);

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: RestoreVolumeToEbsSnapshotRequest
    ) =>
      snapshotsApi.restoreToEbsSnapshot(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
    resourceSnapshots: (
      resourceId: string,
      convertResourceSnapshotsRequest: ConvertResourceSnapshotsRequest
    ) =>
      convertResourceSnapshotsAPI.convertResourceSnapshots(
        currentProjectId,
        resourceId,
        convertResourceSnapshotsRequest
      ),
    otherSnapshotOnDemand: (
      resourceId: string,
      convertOtherSnapshotsOnDemandRequest: ConvertOtherSnapshotsOnDemandRequest
    ) =>
      convertOtherSnapshotsOnDemandApi.convertOtherSnapshotsOnDemand(
        currentProjectId,
        resourceId,
        convertOtherSnapshotsOnDemandRequest
      ),
    listCloudSnapshots: (resourceId: string, day: Date) => {
      const start = dayjs.utc(day).startOf('day').toDate();
      const end = dayjs.utc(day).endOf('day').toDate();

      return httpClient.execute(
        [baseUrl, currentProjectId, resourceId, start, end],
        () =>
          listCloudSnapshotsAPI.listCloudSnapshots(
            currentProjectId,
            resourceId,
            start,
            end
          )
      );
    },
    listDailySnapshots: (resourceId: string) => {
      return httpClient.execute([baseUrl, currentProjectId, resourceId], () =>
        listDailySnapshotsAPI.listDailySnapshots(currentProjectId, resourceId)
      );
    },
  };
};
