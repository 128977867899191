/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class RestoreEksNamespaceInput {
  /**
  * Optional description of the output volume
  */
  'description'?: string;
  /**
  * Optional tags to apply to the output volume
  */
  'tags'?: { [key: string]: string; };
  'volumeSettings': VolumeSettings;
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId'?: string;
  /**
  * Availability zone to restore the volume to.
  */
  'availabilityZone'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreEksNamespaceInput.attributeTypeMap;
  }

  public constructor() {
  }
}

