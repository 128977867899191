import type { Configuration } from '@repo/api-gw-sdk';
import { SecurityScansApi } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/security';

export const security = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const securityScansApi = new SecurityScansApi(config);

  return {
    scansSummary: {
      get: (resourceId: string) =>
        httpClient.execute([baseUrl, resourceId, currentProjectId], () =>
          securityScansApi.getSecurityScansSummary(resourceId, currentProjectId)
        ),
    },
  };
};
