import type {
  CreateAccountInput,
  Configuration,
  Account,
  CloudAccountConfiguration,
  ConnectAwsOrganizationRequest,
  AwsOrganization,
  AccountUpdateInput,
} from '@repo/api-gw-sdk';
import {
  CreateProjectSourceAccountApi,
  ListProjectSourceAccountsApi,
  CreateVaultAccountApi,
  DeleteVaultAccountApi,
  ListVaultAccountsApi,
  CreateProjectRestoreAccountApi,
  DeleteRestoreAccountApi,
  ListProjectRestoreAccountsApi,
  ListScanningAccountsApi,
  GetRestoreCloudAccountConfigurationApi,
  UpdateRestoreCloudAccountConfigurationApi,
  GetRestoreCloudAccountConfigurationsApi,
  AccountsApi,
  UpdateProjectRestoreAccountApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/accounts';

export const cloudAccounts = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listSourceAccountsApi = new ListProjectSourceAccountsApi(config);
  const createSourceAccountApi = new CreateProjectSourceAccountApi(config);
  const listVaultAccountsApi = new ListVaultAccountsApi(config);
  const createVaultAccountApi = new CreateVaultAccountApi(config);
  const deleteVaultAccountApi = new DeleteVaultAccountApi(config);
  const listRestoreAccountsApi = new ListProjectRestoreAccountsApi(config);
  const createRestoreAccountApi = new CreateProjectRestoreAccountApi(config);
  const updateRestoreAccountApi = new UpdateProjectRestoreAccountApi(config);
  const deleteRestoreAccountApi = new DeleteRestoreAccountApi(config);
  const listScanningAccountsApi = new ListScanningAccountsApi(config);
  const accountsApi = new AccountsApi(config);

  const getRestoreCloudAccountConfigurationsApi =
    new GetRestoreCloudAccountConfigurationsApi(config);

  const getRestoreCloudAccountConfigurationApi =
    new GetRestoreCloudAccountConfigurationApi(config);

  const updateRestoreCloudAccountConfigurationApi =
    new UpdateRestoreCloudAccountConfigurationApi(config);

  return {
    source: {
      create: (account: CreateAccountInput) =>
        createSourceAccountApi.createProjectSourceAccount(
          currentProjectId,
          account
        ),
      deactivate: (id: string) =>
        accountsApi.deactivateSourceAccount(currentProjectId, id),
      activate: (id: string) =>
        accountsApi.activateSourceAccount(currentProjectId, id),
      list: (projectId?: string) =>
        httpClient.execute(
          [baseUrl, '/source-accounts', projectId || currentProjectId],
          async () => {
            if (!projectId && !currentProjectId) {
              return;
            }
            return listSourceAccountsApi.listProjectSourceAccounts(
              projectId || currentProjectId
            );
          }
        ),
      listForProjects: (projectIds: string[]) =>
        httpClient.execute(
          [baseUrl, '/source-accounts-multiple', projectIds.join(',')],
          async () =>
            Object.fromEntries<Account[]>(
              await Promise.all(
                projectIds.map((id) =>
                  listSourceAccountsApi
                    .listProjectSourceAccounts(id)
                    .then((x) => [id, x.accounts] as [string, Account[]])
                )
              )
            )
        ),
    },
    vault: {
      create: (account: CreateAccountInput) =>
        createVaultAccountApi.createVaultAccount(account),
      delete: (id: string) => deleteVaultAccountApi.deleteVaultAccount(id),
      list: () =>
        httpClient.execute([baseUrl, '/vault-accounts'], () =>
          listVaultAccountsApi.listVaultAccounts()
        ),
    },
    restore: {
      create: (account: CreateAccountInput) =>
        createRestoreAccountApi.createProjectRestoreAccount(
          currentProjectId,
          account
        ),
      update: (accountId: string, account: AccountUpdateInput) =>
        updateRestoreAccountApi.updateProjectRestoreAccount(
          accountId,
          currentProjectId,
          account
        ),
      delete: (id: string) => deleteRestoreAccountApi.deleteRestoreAccount(id),
      list: () =>
        httpClient.execute(
          [baseUrl, '/restore-accounts', currentProjectId],
          () =>
            listRestoreAccountsApi.listProjectRestoreAccounts(currentProjectId)
        ),
      configuration: {
        list: () =>
          httpClient.execute(
            [
              baseUrl,
              '/restore-accounts',
              currentProjectId,
              'cloud-account-configuration',
            ],
            () =>
              getRestoreCloudAccountConfigurationsApi.getRestoreCloudAccountConfigurations(
                currentProjectId
              )
          ),
        get: (accountId: string) =>
          getRestoreCloudAccountConfigurationApi.getRestoreCloudAccountConfiguration(
            accountId,
            currentProjectId
          ),
        update: (
          accountId: string,
          cloudAccountConfiguration: CloudAccountConfiguration
        ) =>
          updateRestoreCloudAccountConfigurationApi.updateRestoreCloudAccountConfiguration(
            accountId,
            currentProjectId,
            cloudAccountConfiguration
          ),
      },
    },
    scanning: {
      list: () =>
        httpClient.execute([baseUrl, '/scanning-accounts'], () =>
          listScanningAccountsApi.listScanningAccounts()
        ),
      listAsync: () => listScanningAccountsApi.listScanningAccounts(),
    },
    managementGroups: {
      listForProjects: (projectIds: string[]) =>
        httpClient.execute([baseUrl, '/management-groups'], async () =>
          Object.fromEntries<Account[]>(
            await Promise.all(
              projectIds.map((id) =>
                accountsApi
                  .listAzureManagementGroups(id)
                  .then((x) => [id, x.managementGroups] as [string, Account[]])
              )
            )
          )
        ),
      activate: (managementGroupId: string) =>
        accountsApi.activateAzureManagementGroup(
          currentProjectId,
          managementGroupId
        ),
      deactivate: (managementGroupId: string) =>
        accountsApi.deactivateAzureManagementGroup(
          currentProjectId,
          managementGroupId
        ),
    },
    organization: {
      listForProjects: (projectIds: string[]) =>
        httpClient.execute([baseUrl, '/aws-organizations'], async () =>
          Object.fromEntries<AwsOrganization[]>(
            await Promise.all(
              projectIds.map((id) =>
                accountsApi
                  .listAwsOrganizations(id)
                  .then(
                    (x) => [id, x.organizations] as [string, AwsOrganization[]]
                  )
              )
            )
          )
        ),
      connect: (connectAwsOrganizationRequest: ConnectAwsOrganizationRequest) =>
        accountsApi.connectAwsOrganization(
          currentProjectId,
          connectAwsOrganizationRequest
        ),
      activate: (organizationId: string) =>
        accountsApi.activateAwsOrganization(currentProjectId, organizationId),
      deactivate: (organizationId: string) =>
        accountsApi.deactivateAwsOrganization(currentProjectId, organizationId),
    },
  };
};
