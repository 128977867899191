export * from '../models/Account';
export * from '../models/AccountIdFilters';
export * from '../models/AccountUpdateInput';
export * from '../models/AccountsResponse';
export * from '../models/ActionFilters';
export * from '../models/ActivateAwsOrganizationResponse';
export * from '../models/ActivateSourceAccountResponse';
export * from '../models/Ami';
export * from '../models/AmiBlockDevice';
export * from '../models/ApiCredentials';
export * from '../models/ApiCredentialsProperties';
export * from '../models/App';
export * from '../models/AppFilters';
export * from '../models/ApplicationOverride';
export * from '../models/AppsDetails';
export * from '../models/AtlasProject';
export * from '../models/AtlasProjects';
export * from '../models/AuditLog';
export * from '../models/AuditLogAction';
export * from '../models/AuditLogEntitiesInner';
export * from '../models/AuditLogFilterConditions';
export * from '../models/AuthParams';
export * from '../models/AuthResponse';
export * from '../models/AwsDatabaseDestination';
export * from '../models/AwsDatabaseDestinationAwsRds';
export * from '../models/AwsOrganization';
export * from '../models/AzureConnectAccountRequest';
export * from '../models/AzureConnectionIdResponse';
export * from '../models/AzureConsentUrlResponse';
export * from '../models/AzureListSubscriptionsResponse';
export * from '../models/AzureManagementGroup';
export * from '../models/AzureManagementGroups';
export * from '../models/AzureMarketplaceWebhookInput';
export * from '../models/AzureMarketplaceWebhookInputSubscription';
export * from '../models/AzureMarketplaceWebhookInputSubscriptionBeneficiary';
export * from '../models/AzureMarketplaceWebhookInputSubscriptionPurchaser';
export * from '../models/AzureMarketplaceWebhookInputSubscriptionTerm';
export * from '../models/AzureOnboardingRequest';
export * from '../models/AzureOnboardingStatus';
export * from '../models/AzureOnboardingStatusResponse';
export * from '../models/AzureResourceGroup';
export * from '../models/AzureResourceGroups';
export * from '../models/AzureStorageAccount';
export * from '../models/AzureStorageAccounts';
export * from '../models/AzureSubscription';
export * from '../models/AzureTenant';
export * from '../models/BackedUpByResourceType';
export * from '../models/BackedUpByResourceTypeDataInner';
export * from '../models/BackedUpStorageDateHistogram';
export * from '../models/BackupControlViolations';
export * from '../models/BackupJob';
export * from '../models/BackupJobResponse';
export * from '../models/BackupJobSortField';
export * from '../models/BackupJobsFilterConditions';
export * from '../models/BackupPoliciesFilters';
export * from '../models/BackupPolicy';
export * from '../models/BackupPolicyAppliesNotificationArguments';
export * from '../models/BackupPolicyNotificationArguments';
export * from '../models/BackupRegionsFilters';
export * from '../models/BackupSchedule';
export * from '../models/BackupStatus';
export * from '../models/BackupStatusFilters';
export * from '../models/BackupVault';
export * from '../models/BatchApplicationOverrides';
export * from '../models/BatchOperationResponse';
export * from '../models/BooleanFilters';
export * from '../models/CancelExclusionFromBackupResponse';
export * from '../models/Classifications';
export * from '../models/CloudAccountConfiguration';
export * from '../models/CloudAccountConfigurationAwsConfigInner';
export * from '../models/CloudAccountConfigurationAwsSecurityGroup';
export * from '../models/CloudAccountConfigurationAwsSubnet';
export * from '../models/CloudAccountConfigurationInput';
export * from '../models/CloudAccountNotificationArguments';
export * from '../models/CloudAccountType';
export * from '../models/CloudRegion';
export * from '../models/CloudSnapshotsPerVolume';
export * from '../models/ColumnMetadata';
export * from '../models/Condition';
export * from '../models/ConnectAwsOrganizationRequest';
export * from '../models/ConnectAwsOrganizationResponse';
export * from '../models/ConnectSourceAccountRequest';
export * from '../models/ConnectSourceAccountResponse';
export * from '../models/Control';
export * from '../models/ControlRules';
export * from '../models/ControlViolation';
export * from '../models/ControlViolations';
export * from '../models/ControlViolationsPerSeverity';
export * from '../models/ControlViolationsPerSeverityViolatedControlsInner';
export * from '../models/ControlsFilters';
export * from '../models/ConvertOtherSnapshotsOnDemandRequest';
export * from '../models/ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner';
export * from '../models/ConvertResourceSnapshotsRequest';
export * from '../models/ConvertResourceSnapshotsResponse';
export * from '../models/CreateAccountInput';
export * from '../models/CreateApiCredentialsRequest';
export * from '../models/CreateApiCredentialsResponse';
export * from '../models/CreateBackupJobInput';
export * from '../models/CreateBackupVaultInput';
export * from '../models/CreatePolicyInput';
export * from '../models/CreatePolicyScheduleInput';
export * from '../models/CreateProjectInput';
export * from '../models/CreateRoleInput';
export * from '../models/CreateSamlIdentityProviderConfigInput';
export * from '../models/CreateScanJobInput';
export * from '../models/CreateUserInput';
export * from '../models/DBResourceMetadata';
export * from '../models/DBServerRecord';
export * from '../models/DBSnapshotMetadata';
export * from '../models/DBSnapshotType';
export * from '../models/DailySnapshotData';
export * from '../models/DailyStorageSummaries';
export * from '../models/DailyStorageSummary';
export * from '../models/DataAccessRule';
export * from '../models/DataClass';
export * from '../models/DataClassesDetails';
export * from '../models/DataClassesFilters';
export * from '../models/DataClassificationEntity';
export * from '../models/DatabaseProperties';
export * from '../models/DatabaseRecord';
export * from '../models/DeactivateAwsOrganizationResponse';
export * from '../models/DeactivateSourceAccountResponse';
export * from '../models/DestinationDetails';
export * from '../models/DiscoveryInvokeParams';
export * from '../models/DiscoveryJob';
export * from '../models/DiscoveryStatusResponse';
export * from '../models/DriftProtectionPolicy';
export * from '../models/DriftProtectionSummary';
export * from '../models/DriftProtectionTimePoint';
export * from '../models/EncryptionKey';
export * from '../models/EncryptionKeys';
export * from '../models/Environment';
export * from '../models/EnvironmentDetails';
export * from '../models/EnvironmentFilters';
export * from '../models/EonAccount';
export * from '../models/ExcludeFromBackupResponse';
export * from '../models/FileExplorerResponse';
export * from '../models/FileExplorerResult';
export * from '../models/FilePath';
export * from '../models/FileSearchRecord';
export * from '../models/FileSnapshot';
export * from '../models/GenericSnapshotData';
export * from '../models/GetResourceResponse';
export * from '../models/GetSnapshotResponse';
export * from '../models/GroupToRoleMapping';
export * from '../models/IDs';
export * from '../models/IdFilters';
export * from '../models/Identifier';
export * from '../models/InitAuthInput';
export * from '../models/Initiator';
export * from '../models/InitiatorNameFilters';
export * from '../models/InventoryFilterConditions';
export * from '../models/InventoryResource';
export * from '../models/InventoryResourceMetadata';
export * from '../models/InventoryVault';
export * from '../models/InventoryVolume';
export * from '../models/JobExecutionDetails';
export * from '../models/JobStatus';
export * from '../models/JobStatusFilters';
export * from '../models/ListAccountsResponse';
export * from '../models/ListApiCredentialsResponse';
export * from '../models/ListApplicationOverrideResponse';
export * from '../models/ListAuditLogRequest';
export * from '../models/ListAuditLogResponse';
export * from '../models/ListAuditLogsResponse';
export * from '../models/ListAwsOrganizationsResponse';
export * from '../models/ListAzureSKUs200Response';
export * from '../models/ListAzureSKUs200ResponseSkusInner';
export * from '../models/ListAzureSKUsRequest';
export * from '../models/ListAzureStorageAccountContainers200Response';
export * from '../models/ListAzureStorageAccountContainersRequest';
export * from '../models/ListAzureUnassignedNetworkInterfaces200Response';
export * from '../models/ListAzureUnassignedNetworkInterfacesRequest';
export * from '../models/ListBackupJobsInternal200Response';
export * from '../models/ListBackupJobsRequest';
export * from '../models/ListBackupJobsResponse';
export * from '../models/ListBackupPolicyResponse';
export * from '../models/ListBackupVaultResponse';
export * from '../models/ListCloudSnapshotsResponse';
export * from '../models/ListControlViolationsResponse';
export * from '../models/ListControlsResponse';
export * from '../models/ListDailySnapshotsResponse';
export * from '../models/ListDataClassificationEntitiesResponse';
export * from '../models/ListDatabaseSnapshotsResponse';
export * from '../models/ListInstanceProfilesResponse';
export * from '../models/ListInstanceTypesResponse';
export * from '../models/ListInventoryAppsResponse';
export * from '../models/ListInventoryBackupRegionsResponse';
export * from '../models/ListInventoryEnvironmentsResponse';
export * from '../models/ListInventoryItemSnapshotsResponse';
export * from '../models/ListInventoryNetworksResponse';
export * from '../models/ListInventoryRequest';
export * from '../models/ListInventoryResourceGroupsResponse';
export * from '../models/ListInventoryResourceTypesResponse';
export * from '../models/ListInventoryResourcesResponse';
export * from '../models/ListInventorySnapshotsRequest';
export * from '../models/ListInventorySnapshotsResponse';
export * from '../models/ListInventorySourceRegionsResponse';
export * from '../models/ListInventorySubnetsResponse';
export * from '../models/ListInventoryTagsResponse';
export * from '../models/ListNotificationPoliciesResponse';
export * from '../models/ListNotificationsResponse';
export * from '../models/ListProjectsResponse';
export * from '../models/ListResourcesResponse';
export * from '../models/ListRestoreAccountsResponse';
export * from '../models/ListRestoreJobsInternal200Response';
export * from '../models/ListRestoreJobsRequest';
export * from '../models/ListRestoreJobsResponse';
export * from '../models/ListSubnetsResponse';
export * from '../models/ListSubnetsResponseV2';
export * from '../models/ListSubnetsResponseV2SubnetsInner';
export * from '../models/ListUsersResponse';
export * from '../models/MaxRetentionRule';
export * from '../models/Message';
export * from '../models/MinRetentionRule';
export * from '../models/ModelError';
export * from '../models/Notification';
export * from '../models/NotificationArguments';
export * from '../models/NotificationArgumentsRestoreJobFailed';
export * from '../models/NotificationArgumentsRestoreJobSucceeded';
export * from '../models/NotificationPolicy';
export * from '../models/NotificationPolicyConfig';
export * from '../models/NotificationPolicyConfigEmail';
export * from '../models/NotificationPolicyConfigSlack';
export * from '../models/NotificationPolicyConfigSns';
export * from '../models/NotificationStatus';
export * from '../models/NotificationType';
export * from '../models/NumberOfCopiesRule';
export * from '../models/OperationIdFilters';
export * from '../models/OverrideDataClassificationsRequest';
export * from '../models/OverrideDataClassificationsResponse';
export * from '../models/OverrideEnvironmentRequest';
export * from '../models/OverrideEnvironmentResponse';
export * from '../models/Pagination';
export * from '../models/PathSnapshotsRequest';
export * from '../models/PathSnapshotsResponse';
export * from '../models/Preferences';
export * from '../models/Project';
export * from '../models/Provider';
export * from '../models/QueryDBRequest';
export * from '../models/QueryDBResponse';
export * from '../models/QueryDBRestoreDestination';
export * from '../models/QueryDBResultResponse';
export * from '../models/QueryDBStatus';
export * from '../models/QueryDBStatusResponse';
export * from '../models/RegisterInput';
export * from '../models/RegisterInputBillingToken';
export * from '../models/RegisterResponse';
export * from '../models/RequestDetails';
export * from '../models/ResourceDetails';
export * from '../models/ResourceIdFilters';
export * from '../models/ResourceNameFilters';
export * from '../models/ResourceProperties';
export * from '../models/ResourceSortField';
export * from '../models/ResourceTag';
export * from '../models/ResourceType';
export * from '../models/ResourceTypeFilters';
export * from '../models/RestoreAccount';
export * from '../models/RestoreAccountRegions';
export * from '../models/RestoreAtlasClusterInput';
export * from '../models/RestoreAvailabilityZones';
export * from '../models/RestoreAzureDiskInput';
export * from '../models/RestoreAzureVMInput';
export * from '../models/RestoreBucketRequest';
export * from '../models/RestoreDbToRdsInstanceRequest';
export * from '../models/RestoreDbToRdsInstanceRequestDestination';
export * from '../models/RestoreDbToRdsSnapshotRequest';
export * from '../models/RestoreDynamoDBTableInput';
export * from '../models/RestoreEksNamespaceInput';
export * from '../models/RestoreFilesRequest';
export * from '../models/RestoreFilesRequestDestination';
export * from '../models/RestoreImageImageDeviceInput';
export * from '../models/RestoreImageImageInput';
export * from '../models/RestoreImageInput';
export * from '../models/RestoreImageVolumeInput';
export * from '../models/RestoreInstanceImageDeviceInput';
export * from '../models/RestoreInstanceImageInput';
export * from '../models/RestoreInstanceInput';
export * from '../models/RestoreInstanceVolumeInput';
export * from '../models/RestoreJob';
export * from '../models/RestoreJobInitiationResponse';
export * from '../models/RestoreJobSortField';
export * from '../models/RestoreJobsFilterConditions';
export * from '../models/RestoreRdsSubnetGroups';
export * from '../models/RestoreRdsSubnetGroupsRdsSubnetGroupsInner';
export * from '../models/RestoreSecurityGroups';
export * from '../models/RestoreSecurityGroupsSecurityGroupsInner';
export * from '../models/RestoreVolumeToEbsRequest';
export * from '../models/RestoreVolumeToEbsSnapshotRequest';
export * from '../models/Role';
export * from '../models/RolesResponse';
export * from '../models/S3Bucket';
export * from '../models/S3Buckets';
export * from '../models/S3RestoreDestination';
export * from '../models/S3RestoreDestinationS3Bucket';
export * from '../models/S3RestoreTarget';
export * from '../models/S3RestoreTargetS3Bucket';
export * from '../models/SamlIdentityProvider';
export * from '../models/SamlIdentityProviderConfigsResponse';
export * from '../models/SchemaRecord';
export * from '../models/SearchDBResponse';
export * from '../models/SearchFilesResponse';
export * from '../models/SearchInput';
export * from '../models/SecurityScan';
export * from '../models/SecurityScansSummaryResponse';
export * from '../models/ServiceProviderDetails';
export * from '../models/Severity';
export * from '../models/SeverityFilters';
export * from '../models/Snapshot';
export * from '../models/SnapshotFilterConditions';
export * from '../models/SnapshotLocation';
export * from '../models/SnapshotProperties';
export * from '../models/SnapshotPropertiesAzureProperties';
export * from '../models/SnapshotPropertiesAzurePropertiesDiskPropertiesInner';
export * from '../models/SnapshotPropertiesVolumePropertiesInner';
export * from '../models/SnapshotStatus';
export * from '../models/SnapshotStatusFilters';
export * from '../models/SnapshotStorage';
export * from '../models/SortBackupJobBy';
export * from '../models/SortLogsBy';
export * from '../models/SortOrder';
export * from '../models/SortResourceBy';
export * from '../models/SortRestoreJobBy';
export * from '../models/SourceAccount';
export * from '../models/SourceRegionFilters';
export * from '../models/SourceStorage';
export * from '../models/SourceStorageBackupStatus';
export * from '../models/SsoIntegrationSettings';
export * from '../models/State';
export * from '../models/StatusCodeFilters';
export * from '../models/StorageAccountRestoreTarget';
export * from '../models/StorageAccountRestoreTargetStorageAccount';
export * from '../models/SubnetFilters';
export * from '../models/TableRecord';
export * from '../models/TagKeyValuesFilters';
export * from '../models/TagKeysFilters';
export * from '../models/TokenResponse';
export * from '../models/UnifiedDBRecord';
export * from '../models/UnifiedDBServerRecord';
export * from '../models/UnifiedDatabaseRecord';
export * from '../models/UnifiedSchemaRecord';
export * from '../models/UnifiedTableRecord';
export * from '../models/UpdateAccountInput';
export * from '../models/UpdateApiCredentialsRequest';
export * from '../models/UpdateBackupVaultNameInput';
export * from '../models/UpdateControlViolationInput';
export * from '../models/UpdateRoleInput';
export * from '../models/UpdateSamlIdentityProviderInput';
export * from '../models/UpdateUserInput';
export * from '../models/UpdateViewerRoleRequest';
export * from '../models/User';
export * from '../models/UserIdFilters';
export * from '../models/VaultFilters';
export * from '../models/VaultNotificationArguments';
export * from '../models/Viewer';
export * from '../models/VisualizationHistogramSeries';
export * from '../models/VisualizationHistogramTimePoint';
export * from '../models/VisualizationLabel';
export * from '../models/VolumeSettings';
export * from '../models/VpcPropertyFilters';

import { Account } from '../models/Account';
import { AccountIdFilters } from '../models/AccountIdFilters';
import { AccountUpdateInput } from '../models/AccountUpdateInput';
import { AccountsResponse } from '../models/AccountsResponse';
import { ActionFilters } from '../models/ActionFilters';
import { ActivateAwsOrganizationResponse } from '../models/ActivateAwsOrganizationResponse';
import { ActivateSourceAccountResponse } from '../models/ActivateSourceAccountResponse';
import { Ami } from '../models/Ami';
import { AmiBlockDevice } from '../models/AmiBlockDevice';
import { ApiCredentials } from '../models/ApiCredentials';
import { ApiCredentialsProperties } from '../models/ApiCredentialsProperties';
import { App } from '../models/App';
import { AppFilters } from '../models/AppFilters';
import { ApplicationOverride } from '../models/ApplicationOverride';
import { AppsDetails } from '../models/AppsDetails';
import { AtlasProject } from '../models/AtlasProject';
import { AtlasProjects } from '../models/AtlasProjects';
import { AuditLog } from '../models/AuditLog';
import { AuditLogAction } from '../models/AuditLogAction';
import { AuditLogEntitiesInner } from '../models/AuditLogEntitiesInner';
import { AuditLogFilterConditions } from '../models/AuditLogFilterConditions';
import { AuthParams } from '../models/AuthParams';
import { AuthResponse } from '../models/AuthResponse';
import { AwsDatabaseDestination } from '../models/AwsDatabaseDestination';
import { AwsDatabaseDestinationAwsRds } from '../models/AwsDatabaseDestinationAwsRds';
import { AwsOrganization } from '../models/AwsOrganization';
import { AzureConnectAccountRequest, AzureConnectAccountRequestAccountTypeEnum } from '../models/AzureConnectAccountRequest';
import { AzureConnectionIdResponse } from '../models/AzureConnectionIdResponse';
import { AzureConsentUrlResponse } from '../models/AzureConsentUrlResponse';
import { AzureListSubscriptionsResponse } from '../models/AzureListSubscriptionsResponse';
import { AzureManagementGroup } from '../models/AzureManagementGroup';
import { AzureManagementGroups } from '../models/AzureManagementGroups';
import { AzureMarketplaceWebhookInput, AzureMarketplaceWebhookInputActionEnum, AzureMarketplaceWebhookInputStatusEnum, AzureMarketplaceWebhookInputOperationRequestSourceEnum } from '../models/AzureMarketplaceWebhookInput';
import { AzureMarketplaceWebhookInputSubscription, AzureMarketplaceWebhookInputSubscriptionAllowedCustomerOperationsEnum, AzureMarketplaceWebhookInputSubscriptionSessionModeEnum, AzureMarketplaceWebhookInputSubscriptionSandboxTypeEnum, AzureMarketplaceWebhookInputSubscriptionSaasSubscriptionStatusEnum } from '../models/AzureMarketplaceWebhookInputSubscription';
import { AzureMarketplaceWebhookInputSubscriptionBeneficiary } from '../models/AzureMarketplaceWebhookInputSubscriptionBeneficiary';
import { AzureMarketplaceWebhookInputSubscriptionPurchaser } from '../models/AzureMarketplaceWebhookInputSubscriptionPurchaser';
import { AzureMarketplaceWebhookInputSubscriptionTerm, AzureMarketplaceWebhookInputSubscriptionTermTermUnitEnum } from '../models/AzureMarketplaceWebhookInputSubscriptionTerm';
import { AzureOnboardingRequest, AzureOnboardingRequestAccountTypeEnum } from '../models/AzureOnboardingRequest';
import { AzureOnboardingStatus } from '../models/AzureOnboardingStatus';
import { AzureOnboardingStatusResponse } from '../models/AzureOnboardingStatusResponse';
import { AzureResourceGroup } from '../models/AzureResourceGroup';
import { AzureResourceGroups } from '../models/AzureResourceGroups';
import { AzureStorageAccount } from '../models/AzureStorageAccount';
import { AzureStorageAccounts } from '../models/AzureStorageAccounts';
import { AzureSubscription } from '../models/AzureSubscription';
import { AzureTenant } from '../models/AzureTenant';
import { BackedUpByResourceType } from '../models/BackedUpByResourceType';
import { BackedUpByResourceTypeDataInner } from '../models/BackedUpByResourceTypeDataInner';
import { BackedUpStorageDateHistogram } from '../models/BackedUpStorageDateHistogram';
import { BackupControlViolations } from '../models/BackupControlViolations';
import { BackupJob } from '../models/BackupJob';
import { BackupJobResponse } from '../models/BackupJobResponse';
import { BackupJobSortField } from '../models/BackupJobSortField';
import { BackupJobsFilterConditions } from '../models/BackupJobsFilterConditions';
import { BackupPoliciesFilters } from '../models/BackupPoliciesFilters';
import { BackupPolicy } from '../models/BackupPolicy';
import { BackupPolicyAppliesNotificationArguments } from '../models/BackupPolicyAppliesNotificationArguments';
import { BackupPolicyNotificationArguments } from '../models/BackupPolicyNotificationArguments';
import { BackupRegionsFilters } from '../models/BackupRegionsFilters';
import { BackupSchedule } from '../models/BackupSchedule';
import { BackupStatus } from '../models/BackupStatus';
import { BackupStatusFilters } from '../models/BackupStatusFilters';
import { BackupVault } from '../models/BackupVault';
import { BatchApplicationOverrides } from '../models/BatchApplicationOverrides';
import { BatchOperationResponse } from '../models/BatchOperationResponse';
import { BooleanFilters } from '../models/BooleanFilters';
import { CancelExclusionFromBackupResponse } from '../models/CancelExclusionFromBackupResponse';
import { Classifications } from '../models/Classifications';
import { CloudAccountConfiguration } from '../models/CloudAccountConfiguration';
import { CloudAccountConfigurationAwsConfigInner } from '../models/CloudAccountConfigurationAwsConfigInner';
import { CloudAccountConfigurationAwsSecurityGroup } from '../models/CloudAccountConfigurationAwsSecurityGroup';
import { CloudAccountConfigurationAwsSubnet } from '../models/CloudAccountConfigurationAwsSubnet';
import { CloudAccountConfigurationInput } from '../models/CloudAccountConfigurationInput';
import { CloudAccountNotificationArguments } from '../models/CloudAccountNotificationArguments';
import { CloudAccountType } from '../models/CloudAccountType';
import { CloudRegion } from '../models/CloudRegion';
import { CloudSnapshotsPerVolume } from '../models/CloudSnapshotsPerVolume';
import { ColumnMetadata } from '../models/ColumnMetadata';
import { Condition } from '../models/Condition';
import { ConnectAwsOrganizationRequest } from '../models/ConnectAwsOrganizationRequest';
import { ConnectAwsOrganizationResponse } from '../models/ConnectAwsOrganizationResponse';
import { ConnectSourceAccountRequest } from '../models/ConnectSourceAccountRequest';
import { ConnectSourceAccountResponse } from '../models/ConnectSourceAccountResponse';
import { Control } from '../models/Control';
import { ControlRules } from '../models/ControlRules';
import { ControlViolation } from '../models/ControlViolation';
import { ControlViolations } from '../models/ControlViolations';
import { ControlViolationsPerSeverity } from '../models/ControlViolationsPerSeverity';
import { ControlViolationsPerSeverityViolatedControlsInner } from '../models/ControlViolationsPerSeverityViolatedControlsInner';
import { ControlsFilters } from '../models/ControlsFilters';
import { ConvertOtherSnapshotsOnDemandRequest } from '../models/ConvertOtherSnapshotsOnDemandRequest';
import { ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner } from '../models/ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner';
import { ConvertResourceSnapshotsRequest } from '../models/ConvertResourceSnapshotsRequest';
import { ConvertResourceSnapshotsResponse } from '../models/ConvertResourceSnapshotsResponse';
import { CreateAccountInput } from '../models/CreateAccountInput';
import { CreateApiCredentialsRequest } from '../models/CreateApiCredentialsRequest';
import { CreateApiCredentialsResponse } from '../models/CreateApiCredentialsResponse';
import { CreateBackupJobInput } from '../models/CreateBackupJobInput';
import { CreateBackupVaultInput } from '../models/CreateBackupVaultInput';
import { CreatePolicyInput } from '../models/CreatePolicyInput';
import { CreatePolicyScheduleInput } from '../models/CreatePolicyScheduleInput';
import { CreateProjectInput } from '../models/CreateProjectInput';
import { CreateRoleInput } from '../models/CreateRoleInput';
import { CreateSamlIdentityProviderConfigInput } from '../models/CreateSamlIdentityProviderConfigInput';
import { CreateScanJobInput } from '../models/CreateScanJobInput';
import { CreateUserInput } from '../models/CreateUserInput';
import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBServerRecord } from '../models/DBServerRecord';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';
import { DBSnapshotType } from '../models/DBSnapshotType';
import { DailySnapshotData } from '../models/DailySnapshotData';
import { DailyStorageSummaries } from '../models/DailyStorageSummaries';
import { DailyStorageSummary } from '../models/DailyStorageSummary';
import { DataAccessRule } from '../models/DataAccessRule';
import { DataClass } from '../models/DataClass';
import { DataClassesDetails } from '../models/DataClassesDetails';
import { DataClassesFilters } from '../models/DataClassesFilters';
import { DataClassificationEntity } from '../models/DataClassificationEntity';
import { DatabaseProperties } from '../models/DatabaseProperties';
import { DatabaseRecord } from '../models/DatabaseRecord';
import { DeactivateAwsOrganizationResponse } from '../models/DeactivateAwsOrganizationResponse';
import { DeactivateSourceAccountResponse } from '../models/DeactivateSourceAccountResponse';
import { DestinationDetails } from '../models/DestinationDetails';
import { DiscoveryInvokeParams } from '../models/DiscoveryInvokeParams';
import { DiscoveryJob } from '../models/DiscoveryJob';
import { DiscoveryStatusResponse } from '../models/DiscoveryStatusResponse';
import { DriftProtectionPolicy } from '../models/DriftProtectionPolicy';
import { DriftProtectionSummary } from '../models/DriftProtectionSummary';
import { DriftProtectionTimePoint } from '../models/DriftProtectionTimePoint';
import { EncryptionKey } from '../models/EncryptionKey';
import { EncryptionKeys } from '../models/EncryptionKeys';
import { Environment } from '../models/Environment';
import { EnvironmentDetails } from '../models/EnvironmentDetails';
import { EnvironmentFilters } from '../models/EnvironmentFilters';
import { EonAccount } from '../models/EonAccount';
import { ExcludeFromBackupResponse } from '../models/ExcludeFromBackupResponse';
import { FileExplorerResponse } from '../models/FileExplorerResponse';
import { FileExplorerResult } from '../models/FileExplorerResult';
import { FilePath } from '../models/FilePath';
import { FileSearchRecord } from '../models/FileSearchRecord';
import { FileSnapshot } from '../models/FileSnapshot';
import { GenericSnapshotData } from '../models/GenericSnapshotData';
import { GetResourceResponse } from '../models/GetResourceResponse';
import { GetSnapshotResponse } from '../models/GetSnapshotResponse';
import { GroupToRoleMapping } from '../models/GroupToRoleMapping';
import { IDs } from '../models/IDs';
import { IdFilters } from '../models/IdFilters';
import { Identifier } from '../models/Identifier';
import { InitAuthInput } from '../models/InitAuthInput';
import { Initiator } from '../models/Initiator';
import { InitiatorNameFilters } from '../models/InitiatorNameFilters';
import { InventoryFilterConditions } from '../models/InventoryFilterConditions';
import { InventoryResource } from '../models/InventoryResource';
import { InventoryResourceMetadata } from '../models/InventoryResourceMetadata';
import { InventoryVault } from '../models/InventoryVault';
import { InventoryVolume } from '../models/InventoryVolume';
import { JobExecutionDetails } from '../models/JobExecutionDetails';
import { JobStatus } from '../models/JobStatus';
import { JobStatusFilters } from '../models/JobStatusFilters';
import { ListAccountsResponse } from '../models/ListAccountsResponse';
import { ListApiCredentialsResponse } from '../models/ListApiCredentialsResponse';
import { ListApplicationOverrideResponse } from '../models/ListApplicationOverrideResponse';
import { ListAuditLogRequest } from '../models/ListAuditLogRequest';
import { ListAuditLogResponse } from '../models/ListAuditLogResponse';
import { ListAuditLogsResponse } from '../models/ListAuditLogsResponse';
import { ListAwsOrganizationsResponse } from '../models/ListAwsOrganizationsResponse';
import { ListAzureSKUs200Response } from '../models/ListAzureSKUs200Response';
import { ListAzureSKUs200ResponseSkusInner } from '../models/ListAzureSKUs200ResponseSkusInner';
import { ListAzureSKUsRequest } from '../models/ListAzureSKUsRequest';
import { ListAzureStorageAccountContainers200Response } from '../models/ListAzureStorageAccountContainers200Response';
import { ListAzureStorageAccountContainersRequest } from '../models/ListAzureStorageAccountContainersRequest';
import { ListAzureUnassignedNetworkInterfaces200Response } from '../models/ListAzureUnassignedNetworkInterfaces200Response';
import { ListAzureUnassignedNetworkInterfacesRequest } from '../models/ListAzureUnassignedNetworkInterfacesRequest';
import { ListBackupJobsInternal200Response } from '../models/ListBackupJobsInternal200Response';
import { ListBackupJobsRequest } from '../models/ListBackupJobsRequest';
import { ListBackupJobsResponse } from '../models/ListBackupJobsResponse';
import { ListBackupPolicyResponse } from '../models/ListBackupPolicyResponse';
import { ListBackupVaultResponse } from '../models/ListBackupVaultResponse';
import { ListCloudSnapshotsResponse } from '../models/ListCloudSnapshotsResponse';
import { ListControlViolationsResponse } from '../models/ListControlViolationsResponse';
import { ListControlsResponse } from '../models/ListControlsResponse';
import { ListDailySnapshotsResponse } from '../models/ListDailySnapshotsResponse';
import { ListDataClassificationEntitiesResponse } from '../models/ListDataClassificationEntitiesResponse';
import { ListDatabaseSnapshotsResponse } from '../models/ListDatabaseSnapshotsResponse';
import { ListInstanceProfilesResponse } from '../models/ListInstanceProfilesResponse';
import { ListInstanceTypesResponse } from '../models/ListInstanceTypesResponse';
import { ListInventoryAppsResponse } from '../models/ListInventoryAppsResponse';
import { ListInventoryBackupRegionsResponse } from '../models/ListInventoryBackupRegionsResponse';
import { ListInventoryEnvironmentsResponse } from '../models/ListInventoryEnvironmentsResponse';
import { ListInventoryItemSnapshotsResponse } from '../models/ListInventoryItemSnapshotsResponse';
import { ListInventoryNetworksResponse } from '../models/ListInventoryNetworksResponse';
import { ListInventoryRequest } from '../models/ListInventoryRequest';
import { ListInventoryResourceGroupsResponse } from '../models/ListInventoryResourceGroupsResponse';
import { ListInventoryResourceTypesResponse } from '../models/ListInventoryResourceTypesResponse';
import { ListInventoryResourcesResponse } from '../models/ListInventoryResourcesResponse';
import { ListInventorySnapshotsRequest } from '../models/ListInventorySnapshotsRequest';
import { ListInventorySnapshotsResponse } from '../models/ListInventorySnapshotsResponse';
import { ListInventorySourceRegionsResponse } from '../models/ListInventorySourceRegionsResponse';
import { ListInventorySubnetsResponse } from '../models/ListInventorySubnetsResponse';
import { ListInventoryTagsResponse } from '../models/ListInventoryTagsResponse';
import { ListNotificationPoliciesResponse } from '../models/ListNotificationPoliciesResponse';
import { ListNotificationsResponse } from '../models/ListNotificationsResponse';
import { ListProjectsResponse } from '../models/ListProjectsResponse';
import { ListResourcesResponse } from '../models/ListResourcesResponse';
import { ListRestoreAccountsResponse } from '../models/ListRestoreAccountsResponse';
import { ListRestoreJobsInternal200Response } from '../models/ListRestoreJobsInternal200Response';
import { ListRestoreJobsRequest } from '../models/ListRestoreJobsRequest';
import { ListRestoreJobsResponse } from '../models/ListRestoreJobsResponse';
import { ListSubnetsResponse } from '../models/ListSubnetsResponse';
import { ListSubnetsResponseV2 } from '../models/ListSubnetsResponseV2';
import { ListSubnetsResponseV2SubnetsInner } from '../models/ListSubnetsResponseV2SubnetsInner';
import { ListUsersResponse } from '../models/ListUsersResponse';
import { MaxRetentionRule } from '../models/MaxRetentionRule';
import { Message } from '../models/Message';
import { MinRetentionRule, MinRetentionRuleFrequencyEnum } from '../models/MinRetentionRule';
import { ModelError } from '../models/ModelError';
import { Notification } from '../models/Notification';
import { NotificationArguments } from '../models/NotificationArguments';
import { NotificationArgumentsRestoreJobFailed } from '../models/NotificationArgumentsRestoreJobFailed';
import { NotificationArgumentsRestoreJobSucceeded } from '../models/NotificationArgumentsRestoreJobSucceeded';
import { NotificationPolicy, NotificationPolicyTypeEnum } from '../models/NotificationPolicy';
import { NotificationPolicyConfig } from '../models/NotificationPolicyConfig';
import { NotificationPolicyConfigEmail } from '../models/NotificationPolicyConfigEmail';
import { NotificationPolicyConfigSlack } from '../models/NotificationPolicyConfigSlack';
import { NotificationPolicyConfigSns } from '../models/NotificationPolicyConfigSns';
import { NotificationStatus } from '../models/NotificationStatus';
import { NotificationType } from '../models/NotificationType';
import { NumberOfCopiesRule } from '../models/NumberOfCopiesRule';
import { OperationIdFilters } from '../models/OperationIdFilters';
import { OverrideDataClassificationsRequest } from '../models/OverrideDataClassificationsRequest';
import { OverrideDataClassificationsResponse } from '../models/OverrideDataClassificationsResponse';
import { OverrideEnvironmentRequest } from '../models/OverrideEnvironmentRequest';
import { OverrideEnvironmentResponse } from '../models/OverrideEnvironmentResponse';
import { Pagination } from '../models/Pagination';
import { PathSnapshotsRequest } from '../models/PathSnapshotsRequest';
import { PathSnapshotsResponse } from '../models/PathSnapshotsResponse';
import { Preferences } from '../models/Preferences';
import { Project } from '../models/Project';
import { Provider } from '../models/Provider';
import { QueryDBRequest } from '../models/QueryDBRequest';
import { QueryDBResponse } from '../models/QueryDBResponse';
import { QueryDBRestoreDestination } from '../models/QueryDBRestoreDestination';
import { QueryDBResultResponse } from '../models/QueryDBResultResponse';
import { QueryDBStatus } from '../models/QueryDBStatus';
import { QueryDBStatusResponse } from '../models/QueryDBStatusResponse';
import { RegisterInput } from '../models/RegisterInput';
import { RegisterInputBillingToken } from '../models/RegisterInputBillingToken';
import { RegisterResponse } from '../models/RegisterResponse';
import { RequestDetails } from '../models/RequestDetails';
import { ResourceDetails } from '../models/ResourceDetails';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { ResourceNameFilters } from '../models/ResourceNameFilters';
import { ResourceProperties } from '../models/ResourceProperties';
import { ResourceSortField } from '../models/ResourceSortField';
import { ResourceTag } from '../models/ResourceTag';
import { ResourceType } from '../models/ResourceType';
import { ResourceTypeFilters } from '../models/ResourceTypeFilters';
import { RestoreAccount } from '../models/RestoreAccount';
import { RestoreAccountRegions } from '../models/RestoreAccountRegions';
import { RestoreAtlasClusterInput } from '../models/RestoreAtlasClusterInput';
import { RestoreAvailabilityZones } from '../models/RestoreAvailabilityZones';
import { RestoreAzureDiskInput } from '../models/RestoreAzureDiskInput';
import { RestoreAzureVMInput } from '../models/RestoreAzureVMInput';
import { RestoreBucketRequest } from '../models/RestoreBucketRequest';
import { RestoreDbToRdsInstanceRequest } from '../models/RestoreDbToRdsInstanceRequest';
import { RestoreDbToRdsInstanceRequestDestination } from '../models/RestoreDbToRdsInstanceRequestDestination';
import { RestoreDbToRdsSnapshotRequest } from '../models/RestoreDbToRdsSnapshotRequest';
import { RestoreDynamoDBTableInput } from '../models/RestoreDynamoDBTableInput';
import { RestoreEksNamespaceInput } from '../models/RestoreEksNamespaceInput';
import { RestoreFilesRequest } from '../models/RestoreFilesRequest';
import { RestoreFilesRequestDestination } from '../models/RestoreFilesRequestDestination';
import { RestoreImageImageDeviceInput } from '../models/RestoreImageImageDeviceInput';
import { RestoreImageImageInput } from '../models/RestoreImageImageInput';
import { RestoreImageInput } from '../models/RestoreImageInput';
import { RestoreImageVolumeInput } from '../models/RestoreImageVolumeInput';
import { RestoreInstanceImageDeviceInput } from '../models/RestoreInstanceImageDeviceInput';
import { RestoreInstanceImageInput } from '../models/RestoreInstanceImageInput';
import { RestoreInstanceInput } from '../models/RestoreInstanceInput';
import { RestoreInstanceVolumeInput } from '../models/RestoreInstanceVolumeInput';
import { RestoreJob } from '../models/RestoreJob';
import { RestoreJobInitiationResponse } from '../models/RestoreJobInitiationResponse';
import { RestoreJobSortField } from '../models/RestoreJobSortField';
import { RestoreJobsFilterConditions } from '../models/RestoreJobsFilterConditions';
import { RestoreRdsSubnetGroups } from '../models/RestoreRdsSubnetGroups';
import { RestoreRdsSubnetGroupsRdsSubnetGroupsInner } from '../models/RestoreRdsSubnetGroupsRdsSubnetGroupsInner';
import { RestoreSecurityGroups } from '../models/RestoreSecurityGroups';
import { RestoreSecurityGroupsSecurityGroupsInner } from '../models/RestoreSecurityGroupsSecurityGroupsInner';
import { RestoreVolumeToEbsRequest } from '../models/RestoreVolumeToEbsRequest';
import { RestoreVolumeToEbsSnapshotRequest } from '../models/RestoreVolumeToEbsSnapshotRequest';
import { Role } from '../models/Role';
import { RolesResponse } from '../models/RolesResponse';
import { S3Bucket } from '../models/S3Bucket';
import { S3Buckets } from '../models/S3Buckets';
import { S3RestoreDestination } from '../models/S3RestoreDestination';
import { S3RestoreDestinationS3Bucket } from '../models/S3RestoreDestinationS3Bucket';
import { S3RestoreTarget } from '../models/S3RestoreTarget';
import { S3RestoreTargetS3Bucket } from '../models/S3RestoreTargetS3Bucket';
import { SamlIdentityProvider } from '../models/SamlIdentityProvider';
import { SamlIdentityProviderConfigsResponse } from '../models/SamlIdentityProviderConfigsResponse';
import { SchemaRecord } from '../models/SchemaRecord';
import { SearchDBResponse } from '../models/SearchDBResponse';
import { SearchFilesResponse } from '../models/SearchFilesResponse';
import { SearchInput } from '../models/SearchInput';
import { SecurityScan, SecurityScanConclusionEnum } from '../models/SecurityScan';
import { SecurityScansSummaryResponse } from '../models/SecurityScansSummaryResponse';
import { ServiceProviderDetails } from '../models/ServiceProviderDetails';
import { Severity } from '../models/Severity';
import { SeverityFilters } from '../models/SeverityFilters';
import { Snapshot } from '../models/Snapshot';
import { SnapshotFilterConditions } from '../models/SnapshotFilterConditions';
import { SnapshotLocation } from '../models/SnapshotLocation';
import { SnapshotProperties } from '../models/SnapshotProperties';
import { SnapshotPropertiesAzureProperties } from '../models/SnapshotPropertiesAzureProperties';
import { SnapshotPropertiesAzurePropertiesDiskPropertiesInner } from '../models/SnapshotPropertiesAzurePropertiesDiskPropertiesInner';
import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';
import { SnapshotStatus } from '../models/SnapshotStatus';
import { SnapshotStatusFilters } from '../models/SnapshotStatusFilters';
import { SnapshotStorage } from '../models/SnapshotStorage';
import { SortBackupJobBy } from '../models/SortBackupJobBy';
import { SortLogsBy, SortLogsByFieldEnum } from '../models/SortLogsBy';
import { SortOrder } from '../models/SortOrder';
import { SortResourceBy } from '../models/SortResourceBy';
import { SortRestoreJobBy } from '../models/SortRestoreJobBy';
import { SourceAccount } from '../models/SourceAccount';
import { SourceRegionFilters } from '../models/SourceRegionFilters';
import { SourceStorage } from '../models/SourceStorage';
import { SourceStorageBackupStatus } from '../models/SourceStorageBackupStatus';
import { SsoIntegrationSettings } from '../models/SsoIntegrationSettings';
import { State } from '../models/State';
import { StatusCodeFilters } from '../models/StatusCodeFilters';
import { StorageAccountRestoreTarget } from '../models/StorageAccountRestoreTarget';
import { StorageAccountRestoreTargetStorageAccount } from '../models/StorageAccountRestoreTargetStorageAccount';
import { SubnetFilters } from '../models/SubnetFilters';
import { TableRecord } from '../models/TableRecord';
import { TagKeyValuesFilters } from '../models/TagKeyValuesFilters';
import { TagKeysFilters } from '../models/TagKeysFilters';
import { TokenResponse } from '../models/TokenResponse';
import { UnifiedDBRecord } from '../models/UnifiedDBRecord';
import { UnifiedDBServerRecord } from '../models/UnifiedDBServerRecord';
import { UnifiedDatabaseRecord } from '../models/UnifiedDatabaseRecord';
import { UnifiedSchemaRecord } from '../models/UnifiedSchemaRecord';
import { UnifiedTableRecord } from '../models/UnifiedTableRecord';
import { UpdateAccountInput } from '../models/UpdateAccountInput';
import { UpdateApiCredentialsRequest } from '../models/UpdateApiCredentialsRequest';
import { UpdateBackupVaultNameInput } from '../models/UpdateBackupVaultNameInput';
import { UpdateControlViolationInput } from '../models/UpdateControlViolationInput';
import { UpdateRoleInput } from '../models/UpdateRoleInput';
import { UpdateSamlIdentityProviderInput } from '../models/UpdateSamlIdentityProviderInput';
import { UpdateUserInput } from '../models/UpdateUserInput';
import { UpdateViewerRoleRequest } from '../models/UpdateViewerRoleRequest';
import { User } from '../models/User';
import { UserIdFilters } from '../models/UserIdFilters';
import { VaultFilters } from '../models/VaultFilters';
import { VaultNotificationArguments } from '../models/VaultNotificationArguments';
import { Viewer } from '../models/Viewer';
import { VisualizationHistogramSeries } from '../models/VisualizationHistogramSeries';
import { VisualizationHistogramTimePoint } from '../models/VisualizationHistogramTimePoint';
import { VisualizationLabel } from '../models/VisualizationLabel';
import { VolumeSettings } from '../models/VolumeSettings';
import { VpcPropertyFilters } from '../models/VpcPropertyFilters';

/* tslint:disable:no-unused-variable */
let primitives = [
          "string",
          "boolean",
          "double",
          "integer",
          "long",
          "float",
          "number",
          "any"
         ];

let enumsMap: Set<string> = new Set<string>([
  "AuditLogAction",
  "AzureConnectAccountRequestAccountTypeEnum",
  "AzureMarketplaceWebhookInputActionEnum",
  "AzureMarketplaceWebhookInputStatusEnum",
  "AzureMarketplaceWebhookInputOperationRequestSourceEnum",
  "AzureMarketplaceWebhookInputSubscriptionAllowedCustomerOperationsEnum",
  "AzureMarketplaceWebhookInputSubscriptionSessionModeEnum",
  "AzureMarketplaceWebhookInputSubscriptionSandboxTypeEnum",
  "AzureMarketplaceWebhookInputSubscriptionSaasSubscriptionStatusEnum",
  "AzureMarketplaceWebhookInputSubscriptionTermTermUnitEnum",
  "AzureOnboardingRequestAccountTypeEnum",
  "BackupJobSortField",
  "BackupStatus",
  "CloudAccountType",
  "DBSnapshotType",
  "DataClass",
  "Environment",
  "JobStatus",
  "MinRetentionRuleFrequencyEnum",
  "NotificationPolicyTypeEnum",
  "NotificationStatus",
  "NotificationType",
  "Provider",
  "QueryDBStatus",
  "ResourceSortField",
  "ResourceType",
  "RestoreJobSortField",
  "SecurityScanConclusionEnum",
  "Severity",
  "SnapshotStatus",
  "SortLogsByFieldEnum",
  "SortOrder",
  "State",
]);

let typeMap: {[index: string]: any} = {
  "Account": Account,
  "AccountIdFilters": AccountIdFilters,
  "AccountUpdateInput": AccountUpdateInput,
  "AccountsResponse": AccountsResponse,
  "ActionFilters": ActionFilters,
  "ActivateAwsOrganizationResponse": ActivateAwsOrganizationResponse,
  "ActivateSourceAccountResponse": ActivateSourceAccountResponse,
  "Ami": Ami,
  "AmiBlockDevice": AmiBlockDevice,
  "ApiCredentials": ApiCredentials,
  "ApiCredentialsProperties": ApiCredentialsProperties,
  "App": App,
  "AppFilters": AppFilters,
  "ApplicationOverride": ApplicationOverride,
  "AppsDetails": AppsDetails,
  "AtlasProject": AtlasProject,
  "AtlasProjects": AtlasProjects,
  "AuditLog": AuditLog,
  "AuditLogEntitiesInner": AuditLogEntitiesInner,
  "AuditLogFilterConditions": AuditLogFilterConditions,
  "AuthParams": AuthParams,
  "AuthResponse": AuthResponse,
  "AwsDatabaseDestination": AwsDatabaseDestination,
  "AwsDatabaseDestinationAwsRds": AwsDatabaseDestinationAwsRds,
  "AwsOrganization": AwsOrganization,
  "AzureConnectAccountRequest": AzureConnectAccountRequest,
  "AzureConnectionIdResponse": AzureConnectionIdResponse,
  "AzureConsentUrlResponse": AzureConsentUrlResponse,
  "AzureListSubscriptionsResponse": AzureListSubscriptionsResponse,
  "AzureManagementGroup": AzureManagementGroup,
  "AzureManagementGroups": AzureManagementGroups,
  "AzureMarketplaceWebhookInput": AzureMarketplaceWebhookInput,
  "AzureMarketplaceWebhookInputSubscription": AzureMarketplaceWebhookInputSubscription,
  "AzureMarketplaceWebhookInputSubscriptionBeneficiary": AzureMarketplaceWebhookInputSubscriptionBeneficiary,
  "AzureMarketplaceWebhookInputSubscriptionPurchaser": AzureMarketplaceWebhookInputSubscriptionPurchaser,
  "AzureMarketplaceWebhookInputSubscriptionTerm": AzureMarketplaceWebhookInputSubscriptionTerm,
  "AzureOnboardingRequest": AzureOnboardingRequest,
  "AzureOnboardingStatus": AzureOnboardingStatus,
  "AzureOnboardingStatusResponse": AzureOnboardingStatusResponse,
  "AzureResourceGroup": AzureResourceGroup,
  "AzureResourceGroups": AzureResourceGroups,
  "AzureStorageAccount": AzureStorageAccount,
  "AzureStorageAccounts": AzureStorageAccounts,
  "AzureSubscription": AzureSubscription,
  "AzureTenant": AzureTenant,
  "BackedUpByResourceType": BackedUpByResourceType,
  "BackedUpByResourceTypeDataInner": BackedUpByResourceTypeDataInner,
  "BackedUpStorageDateHistogram": BackedUpStorageDateHistogram,
  "BackupControlViolations": BackupControlViolations,
  "BackupJob": BackupJob,
  "BackupJobResponse": BackupJobResponse,
  "BackupJobsFilterConditions": BackupJobsFilterConditions,
  "BackupPoliciesFilters": BackupPoliciesFilters,
  "BackupPolicy": BackupPolicy,
  "BackupPolicyAppliesNotificationArguments": BackupPolicyAppliesNotificationArguments,
  "BackupPolicyNotificationArguments": BackupPolicyNotificationArguments,
  "BackupRegionsFilters": BackupRegionsFilters,
  "BackupSchedule": BackupSchedule,
  "BackupStatusFilters": BackupStatusFilters,
  "BackupVault": BackupVault,
  "BatchApplicationOverrides": BatchApplicationOverrides,
  "BatchOperationResponse": BatchOperationResponse,
  "BooleanFilters": BooleanFilters,
  "CancelExclusionFromBackupResponse": CancelExclusionFromBackupResponse,
  "Classifications": Classifications,
  "CloudAccountConfiguration": CloudAccountConfiguration,
  "CloudAccountConfigurationAwsConfigInner": CloudAccountConfigurationAwsConfigInner,
  "CloudAccountConfigurationAwsSecurityGroup": CloudAccountConfigurationAwsSecurityGroup,
  "CloudAccountConfigurationAwsSubnet": CloudAccountConfigurationAwsSubnet,
  "CloudAccountConfigurationInput": CloudAccountConfigurationInput,
  "CloudAccountNotificationArguments": CloudAccountNotificationArguments,
  "CloudRegion": CloudRegion,
  "CloudSnapshotsPerVolume": CloudSnapshotsPerVolume,
  "ColumnMetadata": ColumnMetadata,
  "Condition": Condition,
  "ConnectAwsOrganizationRequest": ConnectAwsOrganizationRequest,
  "ConnectAwsOrganizationResponse": ConnectAwsOrganizationResponse,
  "ConnectSourceAccountRequest": ConnectSourceAccountRequest,
  "ConnectSourceAccountResponse": ConnectSourceAccountResponse,
  "Control": Control,
  "ControlRules": ControlRules,
  "ControlViolation": ControlViolation,
  "ControlViolations": ControlViolations,
  "ControlViolationsPerSeverity": ControlViolationsPerSeverity,
  "ControlViolationsPerSeverityViolatedControlsInner": ControlViolationsPerSeverityViolatedControlsInner,
  "ControlsFilters": ControlsFilters,
  "ConvertOtherSnapshotsOnDemandRequest": ConvertOtherSnapshotsOnDemandRequest,
  "ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner": ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner,
  "ConvertResourceSnapshotsRequest": ConvertResourceSnapshotsRequest,
  "ConvertResourceSnapshotsResponse": ConvertResourceSnapshotsResponse,
  "CreateAccountInput": CreateAccountInput,
  "CreateApiCredentialsRequest": CreateApiCredentialsRequest,
  "CreateApiCredentialsResponse": CreateApiCredentialsResponse,
  "CreateBackupJobInput": CreateBackupJobInput,
  "CreateBackupVaultInput": CreateBackupVaultInput,
  "CreatePolicyInput": CreatePolicyInput,
  "CreatePolicyScheduleInput": CreatePolicyScheduleInput,
  "CreateProjectInput": CreateProjectInput,
  "CreateRoleInput": CreateRoleInput,
  "CreateSamlIdentityProviderConfigInput": CreateSamlIdentityProviderConfigInput,
  "CreateScanJobInput": CreateScanJobInput,
  "CreateUserInput": CreateUserInput,
  "DBResourceMetadata": DBResourceMetadata,
  "DBServerRecord": DBServerRecord,
  "DBSnapshotMetadata": DBSnapshotMetadata,
  "DailySnapshotData": DailySnapshotData,
  "DailyStorageSummaries": DailyStorageSummaries,
  "DailyStorageSummary": DailyStorageSummary,
  "DataAccessRule": DataAccessRule,
  "DataClassesDetails": DataClassesDetails,
  "DataClassesFilters": DataClassesFilters,
  "DataClassificationEntity": DataClassificationEntity,
  "DatabaseProperties": DatabaseProperties,
  "DatabaseRecord": DatabaseRecord,
  "DeactivateAwsOrganizationResponse": DeactivateAwsOrganizationResponse,
  "DeactivateSourceAccountResponse": DeactivateSourceAccountResponse,
  "DestinationDetails": DestinationDetails,
  "DiscoveryInvokeParams": DiscoveryInvokeParams,
  "DiscoveryJob": DiscoveryJob,
  "DiscoveryStatusResponse": DiscoveryStatusResponse,
  "DriftProtectionPolicy": DriftProtectionPolicy,
  "DriftProtectionSummary": DriftProtectionSummary,
  "DriftProtectionTimePoint": DriftProtectionTimePoint,
  "EncryptionKey": EncryptionKey,
  "EncryptionKeys": EncryptionKeys,
  "EnvironmentDetails": EnvironmentDetails,
  "EnvironmentFilters": EnvironmentFilters,
  "EonAccount": EonAccount,
  "ExcludeFromBackupResponse": ExcludeFromBackupResponse,
  "FileExplorerResponse": FileExplorerResponse,
  "FileExplorerResult": FileExplorerResult,
  "FilePath": FilePath,
  "FileSearchRecord": FileSearchRecord,
  "FileSnapshot": FileSnapshot,
  "GenericSnapshotData": GenericSnapshotData,
  "GetResourceResponse": GetResourceResponse,
  "GetSnapshotResponse": GetSnapshotResponse,
  "GroupToRoleMapping": GroupToRoleMapping,
  "IDs": IDs,
  "IdFilters": IdFilters,
  "Identifier": Identifier,
  "InitAuthInput": InitAuthInput,
  "Initiator": Initiator,
  "InitiatorNameFilters": InitiatorNameFilters,
  "InventoryFilterConditions": InventoryFilterConditions,
  "InventoryResource": InventoryResource,
  "InventoryResourceMetadata": InventoryResourceMetadata,
  "InventoryVault": InventoryVault,
  "InventoryVolume": InventoryVolume,
  "JobExecutionDetails": JobExecutionDetails,
  "JobStatusFilters": JobStatusFilters,
  "ListAccountsResponse": ListAccountsResponse,
  "ListApiCredentialsResponse": ListApiCredentialsResponse,
  "ListApplicationOverrideResponse": ListApplicationOverrideResponse,
  "ListAuditLogRequest": ListAuditLogRequest,
  "ListAuditLogResponse": ListAuditLogResponse,
  "ListAuditLogsResponse": ListAuditLogsResponse,
  "ListAwsOrganizationsResponse": ListAwsOrganizationsResponse,
  "ListAzureSKUs200Response": ListAzureSKUs200Response,
  "ListAzureSKUs200ResponseSkusInner": ListAzureSKUs200ResponseSkusInner,
  "ListAzureSKUsRequest": ListAzureSKUsRequest,
  "ListAzureStorageAccountContainers200Response": ListAzureStorageAccountContainers200Response,
  "ListAzureStorageAccountContainersRequest": ListAzureStorageAccountContainersRequest,
  "ListAzureUnassignedNetworkInterfaces200Response": ListAzureUnassignedNetworkInterfaces200Response,
  "ListAzureUnassignedNetworkInterfacesRequest": ListAzureUnassignedNetworkInterfacesRequest,
  "ListBackupJobsInternal200Response": ListBackupJobsInternal200Response,
  "ListBackupJobsRequest": ListBackupJobsRequest,
  "ListBackupJobsResponse": ListBackupJobsResponse,
  "ListBackupPolicyResponse": ListBackupPolicyResponse,
  "ListBackupVaultResponse": ListBackupVaultResponse,
  "ListCloudSnapshotsResponse": ListCloudSnapshotsResponse,
  "ListControlViolationsResponse": ListControlViolationsResponse,
  "ListControlsResponse": ListControlsResponse,
  "ListDailySnapshotsResponse": ListDailySnapshotsResponse,
  "ListDataClassificationEntitiesResponse": ListDataClassificationEntitiesResponse,
  "ListDatabaseSnapshotsResponse": ListDatabaseSnapshotsResponse,
  "ListInstanceProfilesResponse": ListInstanceProfilesResponse,
  "ListInstanceTypesResponse": ListInstanceTypesResponse,
  "ListInventoryAppsResponse": ListInventoryAppsResponse,
  "ListInventoryBackupRegionsResponse": ListInventoryBackupRegionsResponse,
  "ListInventoryEnvironmentsResponse": ListInventoryEnvironmentsResponse,
  "ListInventoryItemSnapshotsResponse": ListInventoryItemSnapshotsResponse,
  "ListInventoryNetworksResponse": ListInventoryNetworksResponse,
  "ListInventoryRequest": ListInventoryRequest,
  "ListInventoryResourceGroupsResponse": ListInventoryResourceGroupsResponse,
  "ListInventoryResourceTypesResponse": ListInventoryResourceTypesResponse,
  "ListInventoryResourcesResponse": ListInventoryResourcesResponse,
  "ListInventorySnapshotsRequest": ListInventorySnapshotsRequest,
  "ListInventorySnapshotsResponse": ListInventorySnapshotsResponse,
  "ListInventorySourceRegionsResponse": ListInventorySourceRegionsResponse,
  "ListInventorySubnetsResponse": ListInventorySubnetsResponse,
  "ListInventoryTagsResponse": ListInventoryTagsResponse,
  "ListNotificationPoliciesResponse": ListNotificationPoliciesResponse,
  "ListNotificationsResponse": ListNotificationsResponse,
  "ListProjectsResponse": ListProjectsResponse,
  "ListResourcesResponse": ListResourcesResponse,
  "ListRestoreAccountsResponse": ListRestoreAccountsResponse,
  "ListRestoreJobsInternal200Response": ListRestoreJobsInternal200Response,
  "ListRestoreJobsRequest": ListRestoreJobsRequest,
  "ListRestoreJobsResponse": ListRestoreJobsResponse,
  "ListSubnetsResponse": ListSubnetsResponse,
  "ListSubnetsResponseV2": ListSubnetsResponseV2,
  "ListSubnetsResponseV2SubnetsInner": ListSubnetsResponseV2SubnetsInner,
  "ListUsersResponse": ListUsersResponse,
  "MaxRetentionRule": MaxRetentionRule,
  "Message": Message,
  "MinRetentionRule": MinRetentionRule,
  "ModelError": ModelError,
  "Notification": Notification,
  "NotificationArguments": NotificationArguments,
  "NotificationArgumentsRestoreJobFailed": NotificationArgumentsRestoreJobFailed,
  "NotificationArgumentsRestoreJobSucceeded": NotificationArgumentsRestoreJobSucceeded,
  "NotificationPolicy": NotificationPolicy,
  "NotificationPolicyConfig": NotificationPolicyConfig,
  "NotificationPolicyConfigEmail": NotificationPolicyConfigEmail,
  "NotificationPolicyConfigSlack": NotificationPolicyConfigSlack,
  "NotificationPolicyConfigSns": NotificationPolicyConfigSns,
  "NumberOfCopiesRule": NumberOfCopiesRule,
  "OperationIdFilters": OperationIdFilters,
  "OverrideDataClassificationsRequest": OverrideDataClassificationsRequest,
  "OverrideDataClassificationsResponse": OverrideDataClassificationsResponse,
  "OverrideEnvironmentRequest": OverrideEnvironmentRequest,
  "OverrideEnvironmentResponse": OverrideEnvironmentResponse,
  "Pagination": Pagination,
  "PathSnapshotsRequest": PathSnapshotsRequest,
  "PathSnapshotsResponse": PathSnapshotsResponse,
  "Preferences": Preferences,
  "Project": Project,
  "QueryDBRequest": QueryDBRequest,
  "QueryDBResponse": QueryDBResponse,
  "QueryDBRestoreDestination": QueryDBRestoreDestination,
  "QueryDBResultResponse": QueryDBResultResponse,
  "QueryDBStatusResponse": QueryDBStatusResponse,
  "RegisterInput": RegisterInput,
  "RegisterInputBillingToken": RegisterInputBillingToken,
  "RegisterResponse": RegisterResponse,
  "RequestDetails": RequestDetails,
  "ResourceDetails": ResourceDetails,
  "ResourceIdFilters": ResourceIdFilters,
  "ResourceNameFilters": ResourceNameFilters,
  "ResourceProperties": ResourceProperties,
  "ResourceTag": ResourceTag,
  "ResourceTypeFilters": ResourceTypeFilters,
  "RestoreAccount": RestoreAccount,
  "RestoreAccountRegions": RestoreAccountRegions,
  "RestoreAtlasClusterInput": RestoreAtlasClusterInput,
  "RestoreAvailabilityZones": RestoreAvailabilityZones,
  "RestoreAzureDiskInput": RestoreAzureDiskInput,
  "RestoreAzureVMInput": RestoreAzureVMInput,
  "RestoreBucketRequest": RestoreBucketRequest,
  "RestoreDbToRdsInstanceRequest": RestoreDbToRdsInstanceRequest,
  "RestoreDbToRdsInstanceRequestDestination": RestoreDbToRdsInstanceRequestDestination,
  "RestoreDbToRdsSnapshotRequest": RestoreDbToRdsSnapshotRequest,
  "RestoreDynamoDBTableInput": RestoreDynamoDBTableInput,
  "RestoreEksNamespaceInput": RestoreEksNamespaceInput,
  "RestoreFilesRequest": RestoreFilesRequest,
  "RestoreFilesRequestDestination": RestoreFilesRequestDestination,
  "RestoreImageImageDeviceInput": RestoreImageImageDeviceInput,
  "RestoreImageImageInput": RestoreImageImageInput,
  "RestoreImageInput": RestoreImageInput,
  "RestoreImageVolumeInput": RestoreImageVolumeInput,
  "RestoreInstanceImageDeviceInput": RestoreInstanceImageDeviceInput,
  "RestoreInstanceImageInput": RestoreInstanceImageInput,
  "RestoreInstanceInput": RestoreInstanceInput,
  "RestoreInstanceVolumeInput": RestoreInstanceVolumeInput,
  "RestoreJob": RestoreJob,
  "RestoreJobInitiationResponse": RestoreJobInitiationResponse,
  "RestoreJobsFilterConditions": RestoreJobsFilterConditions,
  "RestoreRdsSubnetGroups": RestoreRdsSubnetGroups,
  "RestoreRdsSubnetGroupsRdsSubnetGroupsInner": RestoreRdsSubnetGroupsRdsSubnetGroupsInner,
  "RestoreSecurityGroups": RestoreSecurityGroups,
  "RestoreSecurityGroupsSecurityGroupsInner": RestoreSecurityGroupsSecurityGroupsInner,
  "RestoreVolumeToEbsRequest": RestoreVolumeToEbsRequest,
  "RestoreVolumeToEbsSnapshotRequest": RestoreVolumeToEbsSnapshotRequest,
  "Role": Role,
  "RolesResponse": RolesResponse,
  "S3Bucket": S3Bucket,
  "S3Buckets": S3Buckets,
  "S3RestoreDestination": S3RestoreDestination,
  "S3RestoreDestinationS3Bucket": S3RestoreDestinationS3Bucket,
  "S3RestoreTarget": S3RestoreTarget,
  "S3RestoreTargetS3Bucket": S3RestoreTargetS3Bucket,
  "SamlIdentityProvider": SamlIdentityProvider,
  "SamlIdentityProviderConfigsResponse": SamlIdentityProviderConfigsResponse,
  "SchemaRecord": SchemaRecord,
  "SearchDBResponse": SearchDBResponse,
  "SearchFilesResponse": SearchFilesResponse,
  "SearchInput": SearchInput,
  "SecurityScan": SecurityScan,
  "SecurityScansSummaryResponse": SecurityScansSummaryResponse,
  "ServiceProviderDetails": ServiceProviderDetails,
  "SeverityFilters": SeverityFilters,
  "Snapshot": Snapshot,
  "SnapshotFilterConditions": SnapshotFilterConditions,
  "SnapshotLocation": SnapshotLocation,
  "SnapshotProperties": SnapshotProperties,
  "SnapshotPropertiesAzureProperties": SnapshotPropertiesAzureProperties,
  "SnapshotPropertiesAzurePropertiesDiskPropertiesInner": SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
  "SnapshotPropertiesVolumePropertiesInner": SnapshotPropertiesVolumePropertiesInner,
  "SnapshotStatusFilters": SnapshotStatusFilters,
  "SnapshotStorage": SnapshotStorage,
  "SortBackupJobBy": SortBackupJobBy,
  "SortLogsBy": SortLogsBy,
  "SortResourceBy": SortResourceBy,
  "SortRestoreJobBy": SortRestoreJobBy,
  "SourceAccount": SourceAccount,
  "SourceRegionFilters": SourceRegionFilters,
  "SourceStorage": SourceStorage,
  "SourceStorageBackupStatus": SourceStorageBackupStatus,
  "SsoIntegrationSettings": SsoIntegrationSettings,
  "StatusCodeFilters": StatusCodeFilters,
  "StorageAccountRestoreTarget": StorageAccountRestoreTarget,
  "StorageAccountRestoreTargetStorageAccount": StorageAccountRestoreTargetStorageAccount,
  "SubnetFilters": SubnetFilters,
  "TableRecord": TableRecord,
  "TagKeyValuesFilters": TagKeyValuesFilters,
  "TagKeysFilters": TagKeysFilters,
  "TokenResponse": TokenResponse,
  "UnifiedDBRecord": UnifiedDBRecord,
  "UnifiedDBServerRecord": UnifiedDBServerRecord,
  "UnifiedDatabaseRecord": UnifiedDatabaseRecord,
  "UnifiedSchemaRecord": UnifiedSchemaRecord,
  "UnifiedTableRecord": UnifiedTableRecord,
  "UpdateAccountInput": UpdateAccountInput,
  "UpdateApiCredentialsRequest": UpdateApiCredentialsRequest,
  "UpdateBackupVaultNameInput": UpdateBackupVaultNameInput,
  "UpdateControlViolationInput": UpdateControlViolationInput,
  "UpdateRoleInput": UpdateRoleInput,
  "UpdateSamlIdentityProviderInput": UpdateSamlIdentityProviderInput,
  "UpdateUserInput": UpdateUserInput,
  "UpdateViewerRoleRequest": UpdateViewerRoleRequest,
  "User": User,
  "UserIdFilters": UserIdFilters,
  "VaultFilters": VaultFilters,
  "VaultNotificationArguments": VaultNotificationArguments,
  "Viewer": Viewer,
  "VisualizationHistogramSeries": VisualizationHistogramSeries,
  "VisualizationHistogramTimePoint": VisualizationHistogramTimePoint,
  "VisualizationLabel": VisualizationLabel,
  "VolumeSettings": VolumeSettings,
  "VpcPropertyFilters": VpcPropertyFilters,
}

export class ObjectSerializer {
  public static findCorrectType(data: any, expectedType: string) {
    if (data == undefined) {
      return expectedType;
    } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
      return expectedType;
    } else if (expectedType === "Date") {
      return expectedType;
    } else {
      if (enumsMap.has(expectedType)) {
        return expectedType;
      }

      if (!typeMap[expectedType]) {
        return expectedType; // w/e we don't know the type
      }

      // Check the discriminator
      let discriminatorProperty = typeMap[expectedType].discriminator;
      if (discriminatorProperty == null) {
        return expectedType; // the type does not have a discriminator. use it.
      } else {
        if (data[discriminatorProperty]) {
          var discriminatorType = data[discriminatorProperty];
          if(typeMap[discriminatorType]){
            return discriminatorType; // use the type given in the discriminator
          } else {
            return expectedType; // discriminator did not map to a type
          }
        } else {
          return expectedType; // discriminator was not present (or an empty string)
        }
      }
    }
  }

  public static serialize(data: any, type: string, format: string) {
    if (data === undefined || data === null || primitives.includes(type.toLowerCase())) {
      return data;
    } else if (type.startsWith("Array<", 0)) { 
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let date of data) {
        transformedData.push(ObjectSerializer.serialize(date, subType, format));
      }
      return transformedData;
    } else if (type === "Date") {
      if (format == "date") {
        let month = data.getMonth()+1
        month = month < 10 ? "0" + month.toString() : month.toString()
        let day = data.getDate();
        day = day < 10 ? "0" + day.toString() : day.toString();

        return data.getFullYear() + "-" + month + "-" + day;
      } else {
        return data.toISOString();
      }
    } else {
      if (enumsMap.has(type)) {
        return data;
      }
      if (!typeMap[type]) { // in case we dont know the type
        return data;
      }

      // Get the actual type of this object
      type = this.findCorrectType(data, type);

      // get the map for the correct type.
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      let instance: {[index: string]: any} = {};
      for (let attributeType of attributeTypes) {
        instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
      }
      return instance;
    }
  }

  public static deserialize(data: any, type: string, format: string) {
    // polymorphism may change the actual type.
    type = ObjectSerializer.findCorrectType(data, type);
    if (data == undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let date of data) {
        transformedData.push(ObjectSerializer.deserialize(date, subType, format));
      }
      return transformedData;
    } else if (type === "Date") {
      return new Date(data);
    } else {
      if (enumsMap.has(type)) {// is Enum
        return data;
      }

      if (!typeMap[type]) { // dont know the type
        return data;
      }
      let instance = new typeMap[type]();
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      for (let attributeType of attributeTypes) {
        let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
        if (value !== undefined) {
          instance[attributeType.name] = value;
        }
      }
      return instance;
    }
  }

  /**
   * From a list of possible media types, choose the one we can handle best.
   *
   * The order of the given media types does not have any impact on the choice
   * made.
   */
  public static getPreferredMediaType(mediaTypes: Array<string>): string {
    return "application/json";
  }

}
