/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AwsDatabaseDestinationAwsRds {
  /**
  * Region to restore the database to.
  */
  'restoreRegion': string;
  /**
  * ID of the key you want Eon to use for encrypting the restored database.
  */
  'encryptionKeyId': string;
  /**
  * Name to assign to the restored database.
  */
  'restoredName': string;
  /**
  * List of security group IDs to associate with the restored database. Must be in the same VPC of `subnetGroup`. 
  */
  'securityGroups'?: Array<string>;
  /**
  * Security group ID to associate with the restored database. Must be in the same VPC of `subnetGroup`. 
  */
  'securityGroup'?: string;
  /**
  * Subnet group ID to associate with the restored database. Must be in the same VPC of `securityGroup`. 
  */
  'subnetGroup'?: string;
  /**
  * Tags to apply to the restored database as key-value pairs, where key and value are both strings.
  */
  'tags'?: { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreRegion",
      "baseName": "restoreRegion",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoredName",
      "baseName": "restoredName",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroups",
      "baseName": "securityGroups",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "securityGroup",
      "baseName": "securityGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetGroup",
      "baseName": "subnetGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsDatabaseDestinationAwsRds.attributeTypeMap;
  }

  public constructor() {
  }
}

