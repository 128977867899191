import type { Provider, Configuration } from '@repo/api-gw-sdk';
import {
  GetSourceStorageBackupStatusApi,
  GetBackedUpStorageDateHistogramApi,
  GetBackedUpResourcesApi,
  GetDashboardHomeDriftProtectionApi,
  GetBackupControlViolationsApi,
  DashboardApi,
} from '@repo/api-gw-sdk';

import { toUTCDateOnly } from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';

import type { HttpClient } from './httpClient';
const baseUrl = '/dashboard/home/';

export const dashboards = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const getSourceStorageBackupStatusApi = new GetSourceStorageBackupStatusApi(
    config
  );
  const getBackedUpStorageDateHistogramApi =
    new GetBackedUpStorageDateHistogramApi(config);
  const getBackedUpResourcesApi = new GetBackedUpResourcesApi(config);
  const getDashboardHomeDriftProtectionApi =
    new GetDashboardHomeDriftProtectionApi(config);
  const getBackupControlViolationsApi = new GetBackupControlViolationsApi(
    config
  );
  const dashboardApi = new DashboardApi(config);

  return {
    getSourceStorageBackupStatus: () =>
      httpClient.execute(
        [baseUrl, 'source-storage-backup-status', currentProjectId],
        () =>
          getSourceStorageBackupStatusApi.getSourceStorageBackupStatus(
            currentProjectId
          )
      ),
    getBackedUpStorageDateHistogram: () => {
      return httpClient.execute(
        [baseUrl, 'backed-up-storage-date-histogram', currentProjectId],
        () =>
          getBackedUpStorageDateHistogramApi.getBackedUpStorageDateHistogram(
            currentProjectId
          )
      );
    },
    getBackedUpByCloudProvider: (cloudProvider: Provider) => {
      return httpClient.execute(
        [
          baseUrl,
          'backed-up-by-cloud-provider',
          cloudProvider,
          currentProjectId,
        ],
        () =>
          getBackedUpResourcesApi.getBackedUpResources(
            cloudProvider,
            currentProjectId
          )
      );
    },
    getDriftProtection: () => {
      return httpClient.execute(
        [baseUrl, 'drift-protection', currentProjectId],
        () =>
          getDashboardHomeDriftProtectionApi.getDashboardHomeDriftProtection(
            currentProjectId
          )
      );
    },
    getBackupControlViolations: () => {
      return httpClient.execute(
        [baseUrl, 'backup-control-violations', currentProjectId],
        () =>
          getBackupControlViolationsApi.getBackupControlViolations(
            currentProjectId
          )
      );
    },
    getDailyStorageSummary: () => {
      const start = toUTCDateOnly(new Date());
      start.setDate(start.getDate() - 30); // 30 days ago
      const end = toUTCDateOnly(new Date()); // today

      const startString = dayjs.utc(start).format('YYYY-MM-DD');
      const endString = dayjs.utc(end).format('YYYY-MM-DD');

      return httpClient.execute(
        [
          baseUrl,
          'daily-storage-summary',
          currentProjectId,
          startString,
          endString,
        ],
        () =>
          dashboardApi.getDailyStorageSummaries(
            currentProjectId,
            startString,
            endString
          )
      );
    },
  };
};
